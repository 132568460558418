/*
    Beeline: index.js

    INFORMATION
      This file contains javascript needed for index.php
*/

$(document).ready(function() {
  // ------------------ selectors / global vars
  var formLoginSelector = '#bpmform';
  var fieldApplSelector = '#appl';

  // Var fieldApplNsSelector = '#applns';
  var fieldUserSelector = '#userid';
  var fieldPassSelector = '#passwd';

  // Var fieldConfigSelector = '#config';
  var buttonSubmitSelector = 'button[type="submit"]';
  var appTileSelector = '.apps .app';
  var languageSelector = '.languageSelector > a';

  // ------------------ functions
  var selectApp = function(appTile) {
    var application = $(appTile).data('app');
    var applicationNs = $(appTile).data('app-namespace');
    var applicationUri = $(appTile).data('app-uri');
    var applicationForm = $(appTile).data('app-form');

    // Var applicationBpm = $(appTile).data('app-bpm');
    // Var applicationThird = $(appTile).data('app-third');
    var applicationUriLength = applicationUri.length;
    var windowCurrentUri = window.location;
    var windowNewUri = '';

    // Error handling
    if (applicationUriLength === 0) {
      console.error('local error: can\'t use "' + application + '", no uri found or app not deployed');
    }

    // Handle login
    windowNewUri = new URL(windowCurrentUri);
    if (applicationForm) {
      // With form (send form)
      windowNewUri.searchParams.set('appl', application);
      windowNewUri.searchParams.set('applns', applicationNs);

      // Remove old message if app changed by user
      if (application !== $(fieldApplSelector).val()) {
        windowNewUri.searchParams.delete('msg');
      }
    } else {
      // Without form (redirect)
      windowNewUri = applicationUri;
    }
    window.location = windowNewUri;
  };

  var SelectLanguage = function() {
    var language = $(this).data('language');
    var windowNewUri = new URL(window.location);
    windowNewUri.searchParams.set('bpmlang', language);
    window.location = windowNewUri;
  };

  var focusFirstEmptyFormField = function() {
    var form = $(formLoginSelector);
    if (form.length > 0) {
      var user = $(fieldUserSelector);
      var password = $(fieldPassSelector);
      var submit = $(buttonSubmitSelector);
      if (user.val().length === 0) {
        user.focus();
      } else if (user.val().length > 0 && password.val().length === 0) {
        password.focus();
      } else {
        submit.focus();
      }
    }
  };

  // BEE-26866
  function testAgent(regex) {
    return regex.test(navigator.userAgent.toLowerCase());
  }
  function setBodyCSS() {
    var Body = document.body;

    // eslint-disable-next-line require-unicode-regexp -- IE does not support u flag
    if (testAgent(/trident\/7/)) {
      Body.classList.add('ua-ie11');
    }
  }

  setBodyCSS();

  // ------------------ initialization and handlers

  // Focus
  focusFirstEmptyFormField();

  // App tile clicked
  $(appTileSelector).on('click', function(event) {
    selectApp(event.target.closest(appTileSelector));
  });

  // App tile pressed enter
  $(appTileSelector).on('keypress', function(event) {
    var enterKeyCode = 13;
    if (event.which === enterKeyCode) {
      selectApp(event.target.closest(appTileSelector));
    }
  });

  // Language link clicked
  $(languageSelector).on('click', SelectLanguage);

  // Init tooltips
  $('[data-tooltip]').each(function() {
    var mytitle = $(this).data('tooltip');
    var myposition = $(this).data('tooltip-position');
    if (!myposition) {
      myposition = 'top';
    }
    // eslint-disable-next-line no-new -- External library
    new Tooltip($(this), {
      title: mytitle,
      placement: myposition,
      html: true,
      delay: {
        show: 500, hide: 0
      }
    });
  });
});

document.addEventListener('change', function(Event) {
  var Target = Event.target;
  if ((typeof Target.id !== 'undefined') && (Target.id === 'userid')) {
    var Field = document.getElementById('bpmlogoutother');
    if (Field !== null) {
      $(Field).remove();
    }
  }
});

/* EoF */
